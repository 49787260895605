@font-face {
    font-family: 'Maven Pro';
    src: url('MavenPro.woff2') format('woff2'),
    url('MavenPro.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maven Pro Bold';
    src: url('MavenPro-Bold.woff') format('woff'),
    url('MavenPro-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}
